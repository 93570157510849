import './about.scss'
import { Carousel } from 'antd';

import { useState,useEffect } from 'react'
var React = require("react")



//关于我们
const About:React.FunctionComponent  = () => {
    //左半边的背景图片、不用设为空就行 这样设置为空leftBack = useState("");
    const [leftBack] = useState<string>(require('./../assets//background/about_us_bg.png').default);
    const [tabList] = useState<Array<{name:string,descText:string,imgSrc:string}>>([
        {
            name:"公司背景",
            descText:"北京四翼鸟科技有限公司是一家国内领先的移动互联网产品研发公司，成立于2017年，自成立以来一直专注于移动互联网的创新型技术的科研与突破。目前已在国内垂直行业领域取得了领先水平。",
            imgSrc:require('./../assets/background/company_background_pic.png').default
        },
        {
            name:"产业介绍",
            descText:"公司最初在手机清理工具APP取得成功后，继而扩大至全方位的工具领域创新研发。我们的愿景是通过不断的新型技术驱动，为更多移动互联网用户提供更高效的网络生活平台。特别是针对新兴的不太熟悉互联网的用户群体深度调研，帮助他们的手机上网生活简单化、便捷化。如今公司已经形成由数据中台赋能多产品线的矩阵研发模式，大数据驱动每个产品的每个细节优化，每一步的用户体验都要做到精益求精。在移动互联网行业的技术创新路上我们还在砥砺前行，披荆斩棘。",
            imgSrc:require('./../assets/background/industry_introduction_pic.png').default
        },
        {
            name:"企业文化",
            descText:"在四翼鸟这个大家庭内，聚集了一群心怀梦想、团结协作、充满活力的年轻人。学习创新并勇于尝试是一直以来支撑我们这个团队进步的动力源泉。每个人都坚信我们现在正在做的是一件有意义的事情，终将实现共同的理想-我们不是大厂，我们正在创造大厂！",
            imgSrc:require('./../assets/background/corporate_culture_pic.png').default
        },
    ]);
    //Carousel的ref对象
    const varousel = React.useRef();
    const varousel2 = React.useRef();
    //判断该组件是否hover
    const [isLeftHover,setIsLeftHover] = useState<boolean>(true);
    const [isRightHover,setIsRightHover] = useState<boolean>(true);
    //当前index
    const [currentIndex,setCurrentIndex] = useState(0);

    useEffect(()=>{
        
    })


    /**
     * 切换到下一张
     */
    const next = (val:any) => {
        let index = currentIndex + 1;
        if(index >= tabList.length){
            index = 0
        }
        setCurrentIndex(index)
        varousel.current.next();
        varousel2.current.next();
    }

    /**
     * 切换到上一张
     */
    const prev = () => {
        let index = currentIndex - 1;
        if(index < 0){
            index = tabList.length - 1;
        }
        setCurrentIndex(index)
        varousel.current.prev();
        varousel2.current.prev();
    }
    
    /**
     * 跳转页面
     */
    const goTo = (index: any) => {
        setCurrentIndex(index)
        varousel.current.goTo(index);
        varousel2.current.goTo(index);
    }
    

    return(
        <div className="aboutBigBox" style={{"height":window.innerHeight + 'px'}}>
            {!!leftBack && (
                <div className="letBackBox">
                    <img className="letBack" src={leftBack} alt=""/>
                </div>
            )}
            <div className="aboutPage">
                <div className="leftBox">
                    <div className="titleBox">
                        {
                            tabList.map((item,index)=>{
                                return(
                                    <div onClick={()=>goTo(index)} className={currentIndex == index?"titleItem":"titleItemNo"} key={index}>
                                        <p>{item.name}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="descBox">
                        <Carousel 
                            infinite={true}
                            draggable={false} 
                            vertical={true} 
                            verticalSwiping={true}
                            ref={varousel} 
                            effect="fade" 
                            dots={false}

                        >
                            {
                                tabList.map((item,index)=>{
                                    return(
                                        <div className="descItem" key={index}>
                                            <p>{item.descText}</p>
                                        </div>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                    <div className="dotsBoxBtn"
                    >
                        <div className="leftIconBox"
                            onMouseEnter={()=>setIsLeftHover(false)}
                            onMouseLeave={()=>setIsLeftHover(true)}
                            onClick={()=>prev()}
                        >
                            <img
                                className="IconImg"
                                src={
                                    !!isLeftHover?
                                    require('./../assets/icon/switch_left_uncheck.png').default
                                    :
                                    require('./../assets/icon/switch_left_check.png').default
                                } alt=""/>
                        </div>
                        <div className="leftIconBox"
                            onMouseEnter={()=>setIsRightHover(false)}
                            onMouseLeave={()=>setIsRightHover(true)}
                            onClick={(val)=>next(val)}
                        >
                            <img
                                className="IconImg"
                                src={
                                    !!isRightHover?
                                    require('./../assets/icon/switch_right_uncheck.png').default
                                    :
                                    require('./../assets/icon/switch_right_check.png').default
                            } alt=""/>
                        </div>
                    </div>
                </div>
                <div className="backImgBox">
                    <Carousel 
                        infinite={true}
                        draggable={false} 
                        vertical={true} 
                        verticalSwiping={true}
                        ref={varousel2} 
                        effect="fade" 
                        dots={false}
                    >
                        {
                            tabList.map((item,index)=>{
                                return(
                                    <div key={index}>
                                        <img className="backImg" src={item.imgSrc} alt=""/>
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </div>
            </div>
        </div>
    )

}

export default About