
import './tabBar.scss'
import { useState } from 'react'

type propsType = {
    currentIndex:number,
    setPropsCurrentIndex:Function,
    tabList:Array<{name:string}>
}
const TabBar:React.FunctionComponent<propsType>  = (props) => {

    const setPropsCurrentIndex = props.setPropsCurrentIndex
    const currentIndex = props.currentIndex
    const tabList = props.tabList


    return(
        <div className="tabBarBox">
            <img className="logoImg" src={(currentIndex == 0 || currentIndex == 4)?
                    require('./../assets/icon/logo_white.png').default
                :
                    require('./../assets/icon/logo.png').default} alt=""
            />
            <div className="tabListBox">
                {
                    tabList.map((item:{name:string},index:number)=>{
                        return(
                            <div onClick={()=>setPropsCurrentIndex(index)} className="tabList" key={index}>
                                <text className={
                                    (currentIndex == 0 || currentIndex == 4)?
                                        "tabText"
                                        :
                                        currentIndex == index?
                                            "tabTextBlue"
                                            :
                                            "tabTextBlack"
                                    }>{item.name}</text>
                                {
                                    currentIndex == index && (
                                        <img className="dotsIcon" src={
                                            (currentIndex == 0 || currentIndex == 4)?
                                                require('./../assets/icon/header_select_white.png').default
                                                :
                                                currentIndex == 0?
                                                    require('./../assets/icon/header_select_white.png').default
                                                    :
                                                    require('./../assets/icon/header_select.png').default
                                        } alt=""/>
                                    )
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default TabBar