import './banner.scss'
import Slider from "react-slick";

import { Carousel } from 'antd';
import { useEffect, useRef, useState } from 'react';
var React = require("react")

type propsType = {
    children:any,
    setPropsCurrentIndex:Function,   //修改父组件的currentIndex
    propsCurrentIndex:number,   //父组件传过来的currentIndex
    setPropsCurrentTabBarIndex:Function
}
const Banner:React.FunctionComponent<propsType>  = (props) => {
    const [childrenList] = useState(props.children);
    const setPropsCurrentIndex = props.setPropsCurrentIndex;
    const propsCurrentIndex = props.propsCurrentIndex
    
    //判断有多少个子节点
    const [count,setCount] = useState<Array<number>>([]);
    //判断该组件是否hover   null鼠标没有放上去  0放到第一个点上边、1放到第二个点上边...
    const [isHover,setIsHover] = useState<number | null>(null);
    //Carousel的ref对象
    const varousel = React.useRef();
    //轮播图的下标
    const [currentIndex,setCurrentIndex] = useState(0);

    useEffect(()=>{
        
        // goTo(propsCurrentIndex);
        varousel.current.slickGoTo(propsCurrentIndex)
        //判断有多少个子children
        if(!!childrenList){
            let listCount = [];
            for(var i = 0; i < React.Children.count(childrenList);i++){
                listCount.push(i)
            }
            setCount(listCount)
        }
    },[propsCurrentIndex])
    /**
     * 切换轮播页面方法
     * @param index 页面的下标
     */
    const goTo = (index: any) => {
        setIsHover(index)
        setPropsCurrentIndex(index)
        setCurrentIndex(index)
        // varousel.current.slickGoTo(index);
    }

    const settings = {
        dots: true,
        infinite: false,    //无线滚动
        slidesToShow: 1,
        slidesPerRow:1,
        slidesToScroll:1,
        // accessibility:true, //启动方向键导航
        adaptiveHeight:true,    //自动高度
        vertical: true,
        draggable:false,
        verticalSwiping: true,
        beforeChange: function(currentSlide:any, nextSlide:any) {
            console.log("before change", currentSlide, nextSlide);
            goTo(nextSlide)
            setTimeout(()=>{
                props.setPropsCurrentTabBarIndex(nextSlide)
            },200)
        },
        afterChange: function(currentSlide:any) {
            // console.log("after change", currentSlide);
            // goTo(currentSlide)
        }
      }

    return(
        <div className="bannerBox">
            {/* 指示点 */}
            <div className="dotsBox">
                {
                    !!count && count.map((item,index)=>{
                        return(
                            <img className="dotsIcon"
                                key={index}
                                onMouseEnter={()=>setIsHover(index)}
                                onMouseLeave={()=>setIsHover(null)}
                                src={
                                    (currentIndex == 0 || currentIndex == 4)?
                                        currentIndex == index?
                                            require('./../assets/icon/right_white_select.png').default
                                        :
                                            isHover == index?
                                                require('./../assets/icon/right_white_select.png').default
                                            :require('./../assets/icon/right_white_unselect.png').default
                                    :
                                        currentIndex == index?
                                            require('./../assets/icon/right_grey_select.png').default
                                        :
                                            isHover == index?
                                                require('./../assets/icon/right_grey_select.png').default
                                            :require('./../assets/icon/right_grey_unselect.png').default
                                }
                                onClick={()=>goTo(index)}
                                ></img>
                        )
                    })
                }
            </div>
            {/* <Carousel 
                afterChange={(val)=>goTo(val)}
                infinite={true}
                draggable={false} 
                vertical={true} 
                verticalSwiping={true}
                ref={varousel} 
                // effect="fade" 
                effect="scrollx"
                dots={false}
            >
                { props.children }
            </Carousel> */}
            <Slider {...settings} ref={varousel}>
                { props.children }
            </Slider>
        </div>
    )

}

export default Banner