import './recruit.scss'

import { useState } from 'react'
import { Carousel } from 'antd';
import Index from '../index';
var React = require("react")


//招贤纳士
const Recruit:React.FunctionComponent  = () => {
    /*
    数据列表
        name:职位名称
        needText:职位要求
        descText:职位描述
        backImg:背景图片
    */
    const [dataList,setDataList] = useState<Array<{name:string,needText:Array<string>,descText:Array<string>,backImg:string}>>([
        {
            name:"信息流优化师",
            descText:[
                "1、自有产品负责在各大信息流渠道，广告投放及优化工作；",
                "2、根据每日投放数据进行数据分析，针对数据变化情况制定优化策略并做出调整；",
                "3、分周期统计各账户数据，并进行数据分析作出优化方案；",
                "4、针对部分需要更新素材的渠道，撰写文案，与设计对接及时更新素材进行效果测试；",
            ],
            needText:[
                "1、大专以上学历，熟练使用office办公软件，精通excel；电子商务、设计、统计学、广告营销、经济学等专业者优先考虑；",
                "2、具有较强的沟通协调能力，数据敏感度高；",
                "3、对移动APP感兴趣，愿意研究产品的特点及受众群体；",
                "4、具备创新思维，积极主动接受新鲜事物，敢于接受工作挑战；",
                "5、工作认真负责，具有团队合作精神，能承受工作压力；",
                "6、今日头条，广点通投放经验者优先。",
            ],
            backImg:require('./../assets/background/recruit_optimizer_pic.png').default
        },{
            name:"信息流设计师",
            descText:[
                "1、负责公司产品及短视频类的市场推广素材制作与优化（广告创意、banner、落地页、icon等）；",
                "2、根据公司项目独立完成信息流广告视频的剪辑等后期制作；",
                "3、结合产品特色及用户属性进行差异化设计，提升广告点击率、转化率，同时结合投放数据分析优化素材；",
                "4、定期关注并搜集竞品及各广告平台广告素材信息，准确把握国内产品用户心理及偏好；",
            ],
            needText:[
                "1、大专以上学历，广告、设计、动画等设计相关专业，3年左右平面及设计相关工作经验；",
                "2、精通并熟练掌握PS、AI、Premiere或FinalcutPro、AE等，有一定手绘能力；",
                "3、扎实的美术功底，良好的的审美能力及广告敏锐度；",
                "4、有一定的想象力和创造力，具有对创意构想的表达能力；",
                "5、具备文案能力，思维敏捷，有广告行业相关工作经验者优先；",
                "6、良好的的沟通和抗压能力，高度的责任心和团队合作精神；",
                "7、学习能力强，具有较强的独立思考能力；"
            ],
            backImg:require('./../assets/background/recruit_design_pic.png').default
        },{
            name:"Android开发工程师 ",
            descText:[
                "1、负责公司Android平台产品研发；",
                "2、负责Android代码设计和编写；",
                "3、调研Android平台新技术并能够应用到公司产品开发中；",
                "4、负责安卓端页面动效的实现",
            ],
            needText:[
                "1、计算机或相关专业专科以上学历；",
                "2、熟练掌握Java语言，熟悉常用的Java库，对于设计模式，多线程等技术有良好的理解和掌握；",
                "3、熟练掌握AndroidSDK，对Android程序的性能优化有丰富经验者优先；",
                "4、良好的程序架构设计和编码品味，热爱编程，能产出高质量的代码；",
                "5、积极乐观，认真负责，乐于协作。",
                "注意:身份证上00后的暂不考虑",
            ],
            backImg:require('./../assets/background/recruit_android_pic.png').default
        }
    ])
    


    return(
        <div className="recruittBox" style={{"height":window.innerHeight + 'px'}}>
            <p className="pageTitle">招贤纳士</p>
            <div className="contentBox">
                <Carousel
                    effect="scrollx"
                    dots={true}
                    draggable={true}
                    autoplay={true}
                    >
                    {dataList.map((item,index)=>{
                        return(
                            <div key={index}>
                                <div key={index} className="itemBox">
                                    <div className="itemLeftBox">
                                        <p className="itemText">{item.name}</p>
                                        <p className="descTitle">职位描述</p>
                                        {item.descText.map((item2,index2)=>{
                                            return(
                                                <p className="descText">{item2}</p>
                                            )
                                        })}
                                        <p className="needTitle">职位要求</p>
                                        {item.needText.map((item2,index2)=>{
                                            return(
                                                <p className="descText">{item2}</p>
                                            )
                                        })}
                                    </div>
                                    <div className="backBox">
                                        <img className="backImg" src={item.backImg} alt=""/>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Carousel>
            </div>
            <div className="btnBox" onClick={()=>window.open("https://www.zhipin.com/wapi/zpAntispam/verify/sliderNew?u=IA%7E%7E&callbackUrl=http%3A%2F%2Fwww.zhipin.com%2Fgongsir%2F5934dc6b22101bb00XR42N--.html%3Fka%3Dall-jobs-hot&p=IfdC9Ge0Q2sCRXcpPKg%7E")}>
                <p className="subText">我要投简历</p>
            </div>
            <img className="backBigImg" src={require('./../assets/background/recruit_bg.png').default} alt=""/>
        </div>
    )

}

export default Recruit