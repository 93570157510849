import './index.scss'

import Banner from '../components/banner'
import { useState } from 'react'

import TabBar from './../components/tabBar'
import Home from './../home/home'
import About from './../aboutWe/about'
import Product from './../product/product'
import Recruit from './../recruit/recruit'
import CallWe from './../callWe/callWe'
import Bottom from './../components/bottom'

const Index:React.FunctionComponent  = () => {
    const [tabList] = useState<Array<{name:string}>>([
        {name:"首页"},
        {name:"关于我们"},
        {name:"我的产品"},
        {name:"招贤纳士"},
        {name:"联系我们"},
    ])
    //首页banner列表
    const [bannerList] = useState<Array<{imgSrc:string}>>([
        {imgSrc:require('./../assets/background/banner1.jpg').default},
        {imgSrc:require('./../assets/background/banner2.jpg').default},
        {imgSrc:require('./../assets/background/banner3.jpg').default},
        {imgSrc:require('./../assets/background/banner4.jpg').default}
    ])
    //记录轮播图下标
    const [currentIndex,setCurrentIndex] = useState(0);
    //tabBar下标需要延迟一下
    const [currentTabBarIndex,setCurrentTabBarIndex] = useState(0);

    // 节流throttle代码（时间戳+定时器）：
    function throttle(fn:any, ms:any) {
        let timerId:any // 创建一个标记用来存放定时器的id
        return function () {
            // 没有定时器等待执行，则表示可以创建新的定时器来执行函数
            if (!timerId) {
                timerId = setTimeout(() => {
                    // 定时器id清空，表示可以执行下一次调用了
                    timerId = null
                    //@ts-ignore
                    fn.apply(this, arguments)
                }, ms)
            }
        }
    }
    windowAddMouseWheel();
    function windowAddMouseWheel() {
        var scrollFunc = function (e:any) {
            console.log("来了来了")
            e = e || window.event;
            if (e.wheelDelta) {  //判断浏览器IE，谷歌滑轮事件
                if (e.wheelDelta > 0) { //当滑轮向上滚动时
                    if(currentIndex > 0){
                        setCurrentIndex(currentIndex - 1);
                    }
                }
                if (e.wheelDelta < 0) { //当滑轮向下滚动时
                    if(currentIndex < 4){
                        setCurrentIndex(currentIndex + 1);
                    }
                }
            } else if (e.detail) {  //Firefox滑轮事件
                if (e.detail> 0) { //当滑轮向上滚动时
                    if(currentIndex > 0){
                        setCurrentIndex(currentIndex - 1);
                    }
                }
                if (e.detail< 0) { //当滑轮向下滚动时
                    if(currentIndex < 4){
                        setCurrentIndex(currentIndex + 1);
                    }
                }
            }
        };
        //给页面绑定滑轮滚动事件
        // if (document.addEventListener) {
        //     document.addEventListener('DOMMouseScroll', throttle(scrollFunc,300), false);
        // }
        //滚动滑轮触发scrollFunc方法
        window.onmousewheel = throttle(scrollFunc,500);
    }

    return(
        <div className="indexBox">
            <div className="tabBox" style={{backgroundColor:(currentTabBarIndex == 0 || currentTabBarIndex == 4)?"":"#fff"}}>
                <TabBar tabList={tabList} currentIndex={currentTabBarIndex?currentTabBarIndex:0}  setPropsCurrentIndex={setCurrentIndex}></TabBar>
            </div>
            <Banner setPropsCurrentIndex={setCurrentIndex} propsCurrentIndex={currentIndex} setPropsCurrentTabBarIndex={setCurrentTabBarIndex}>
                {tabList.map((item,index)=>{
                    if(index == 0){ // 显示首页
                        return(
                            <div className="qwe" key={index}>
                                {/* <div className="tabBox">
                                    <TabBar tabList={tabList} currentIndex={currentIndex}  setPropsCurrentIndex={setCurrentIndex}></TabBar>
                                </div> */}
                                <Home></Home>
                            </div>
                        )
                    }else if(index == 1){
                        return(
                            <div className="qwe" key={index}>
                                {/* <div className="tabBox">
                                    <TabBar tabList={tabList} currentIndex={currentIndex}  setPropsCurrentIndex={setCurrentIndex}></TabBar>
                                </div> */}
                                <About></About>
                                {/* <Home></Home> */}
                            </div>
                        )
                    }else if(index == 2){
                        return(
                            <div className="qwe" key={index}>
                                {/* <div className="tabBox">
                                    <TabBar tabList={tabList} currentIndex={currentIndex}  setPropsCurrentIndex={setCurrentIndex}></TabBar>
                                </div> */}
                                <Product></Product>
                                {/* <Home></Home> */}
                            </div>
                        )
                    }else if(index == 3){
                        return(
                            <div className="qwe" key={index}>
                                {/* <div className="tabBox">
                                    <TabBar tabList={tabList} currentIndex={currentIndex}  setPropsCurrentIndex={setCurrentIndex}></TabBar>
                                </div> */}
                                <Recruit></Recruit>
                                {/* <Home></Home> */}
                            </div>
                        )
                    }else if(index == 4){
                        return(
                            <div className="qwe" key={index}>
                                {/* <div className="tabBox">
                                    <TabBar tabList={tabList} currentIndex={currentIndex}  setPropsCurrentIndex={setCurrentIndex}></TabBar>
                                </div> */}
                                <CallWe setCurrentIndex={setCurrentIndex}></CallWe>
                                {/* <Home></Home> */}
                            </div>
                        )
                    }else{
                        return(
                            <div></div>
                        )
                    }
                })}
            </Banner>
            {/* {currentIndex == 4 && (
                <Bottom setPropsCurrentIndex={setCurrentIndex}></Bottom>
            )} */}
        </div>
    )

}

export default Index