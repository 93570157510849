import './callWe.scss'
import Bottom from './../components/bottom'

import { useState } from 'react'
import { Carousel } from 'antd';
var React = require("react")


//招贤纳士
type propsType = {
    setCurrentIndex:Function,  //修改首页选中下标
}
const Recruit:React.FunctionComponent<propsType>  = (props) => {
    const [textList] = useState([
        "邮箱：zk@dmpdsp.com",
        // "手机：13621354508",
        "座机：010-80829671",
        "地址：北京市大兴区亦庄镇荣华南路2号院大族广场T4号楼1103"
    ])
    const setCurrentIndex = props.setCurrentIndex

    return(
        <div className="callWeBox" style={{"height":document.documentElement.clientHeight}}>
            <img className="backImg" src={require('./../assets/background/contact_us_bg.png').default} alt=""/>
            <div className="contentBigBox">
                <div className="contentBox">
                    <p className="titleText">联系我们</p>
                    <div className="line"></div>
                    {textList.map((item,index)=>{
                        return(
                            <div key={index}>
                                <p className="itemText">{item}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Bottom setPropsCurrentIndex={setCurrentIndex}></Bottom>
        </div>
    )

}

export default Recruit