import './product.scss'

import {useState} from 'react'
import {Carousel} from 'antd';

var React = require("react")


//我的产品
const Product: React.FunctionComponent = () => {

    /*data列表
        name:列表名称
        imgIcon:顶部图标
        backImg:背景图片
        descText:描述文件
    */
    const [tabList] = useState<Array<{ name: string, imgIcon: string, backImg: string, descText: string }>>([

        {
            name: "天天走路宝",
            imgIcon: require('./../assets/icon/ttzlb.png').default,
            backImg: require('./../assets/background/ttzlb_bg.jpeg').default,
            descText: `天天走路宝是一款记录运动计步的软件，主要功能是，记录走路时长，步数，大致消耗热量情况，可以统计每日运动数据，天天走路让身体更健康。快来体验加入我们的运动行列。`
        },
        {
            name: "斗转",
            imgIcon: require('./../assets/icon/douzhuan.png').default,
            backImg: require('./../assets/background/qmyjjsb_page.png').default,
            descText: `斗转app是一款专注于图文编辑与音乐相册制作的手机应用。用户通过斗转app，可以轻松将照片变成动感音乐相册、排版制作的精美图文以及个性杂志封面，让你的照片惊艳朋友圈。斗转app拥有超多精美相册模板，用户可以自由选择喜欢的模板类型，并给照片添加文字、图片元素，让每张照片都充满故事。`
        },
        {
            name: "成语中状元",
            imgIcon: require('./../assets/icon/cyzzy.png').default,
            backImg: require('./../assets/background/cyzzy_page.jpg').default,
            descText: `汇聚了大量成语释义的教育类软件，在软件中用户可以通过查看成语释义增长知识、获得成长。成语数量众多且丰富，相信可以吸引各类用户在APP中学习并产生进步。`
        },
        {
            name: "明日天气2021",
            imgIcon: require('./../assets/icon/mrtq.png').default,
            backImg: require('./../assets/background/mrtq_page.png').default,
            descText: `明天天气是一款专业的天气APP，支持国内3000多个市县的天气预报和实时天气预警信息。各种天气状况从容应对，帮你做好生活决策，为你出行保驾护航。`
        },
        {
            name: "答题我最强",
            imgIcon: require('./../assets/icon/dtwzq.png').default,
            backImg: require('./../assets/background/dtwzq_page.jpg').default,
            descText: `app内汇聚了大量百科知识、古诗典故和成语释义的教育类软件，在软件中用户可以通过查看释义和答题闯关两种模式，由浅入深地了解到各类不同的知识。`
        },
        {
            name: "闪电清理大师",
            imgIcon: require('./../assets/icon/shandianqinglidashi_logo.png').default,
            backImg: require('./../assets/background/shandianqinglidashi_page.png').default,
            descText: `闪电清理大师是一款完全免费的手机加速与空间清理软件，强力加速使手机运行更流畅，一键清理快速解决空间不足问题。 【一键清理】一键搞定无用垃圾，手机清理更省心 【微信清理】深度清理微信垃圾，微信聊天更流畅 【相册清理】自动筛选不美照片，留住精彩瞬间 等等。`
        },
        {
            name: "66输入法",
            imgIcon: require('./../assets/icon/66input.png').default,
            backImg: require('./../assets/background/66input_1.png').default,
            descText: `66输入法产品具有多项独特特点，包括手写输入、点击屏幕朗读功能、播报输入文字功能以及语音输入功能。66输入法稳定可靠，它可以实现高效的输入体验，而且还不会给您带来任何干扰和不便，您可以放心使用我们的输入法产品，轻松地进行输入操作。`
        },
        // {
        //     name:"闪电WiFi",
        //     imgIcon:require('./../assets/icon/sdwifi.png').default,
        //     backImg:require('./../assets/background/wnwifi_page.png').default,
        //     descText:`致力于为用户提供免费、安全、稳定的上网服务，让用户随时随地轻松接入无线网络，使用各种在线服务，不再为无网、断网而苦恼，是您的WiFi连接必备工具。`
        // },
        // {
        //     name:"爱上清理",
        //     imgIcon:require('./../assets/icon/qmzc.png').default,
        //     backImg:require('./../assets/background/qmzc_page.jpg').default,
        //     descText:`帮助你快速清理内存，节省空间！
        //     【超级加速】瞬间释放手机内存，保证手机运行流畅
        //     【超级省电】快速检查手机电量，一键停止耗电程序`
        // },
        // {
        //     name:"蜜蜂WiFi",
        //     imgIcon:require('./../assets/icon/mfwifi.png').default,
        //     backImg:require('./../assets/background/wnwifi_page.png').default,
        //     descText:`致力于为用户提供免费、安全、稳定的上网服务，让用户随时随地轻松接入无线网络，使用各种在线服务，不再为无网、断网而苦恼，是您的WiFi连接必备工具。`
        // },
        // {
        //         name:"超快清理大师",
        //         imgIcon:require('./../assets/icon/chaokuaiqingli.png').default,
        //         backImg:require('./../assets/background/chaokuaiqingli_page.png').default,
        //         descText:`超快清理大师是一款清理加速，降温省电，娱乐视频的软件。可以很好的帮助您智能识别相似图片，清理手机内冗余相片；一键开启通知防护免骚扰打扰，分类管理杂乱通知；快速检查手机垃圾，一键解决所有垃圾瞬间释放手机内存，保证手机运行流畅及时反应手机存储，实现快速软件卸载`
        //     }
        // ,{
        //     name:"闪电清理大师",
        //     imgIcon:require('./../assets/icon/shandianqingli.png').default,
        //     backImg:require('./../assets/background/shandianqingli_page.png').default,
        //     descText:`闪电清理大师是一款免费的手机安全管理工具，可以帮助您快速检查手机垃圾，一键解决所有垃圾；瞬间释放手机内存，保证手机运行流畅；及时反应手机存储，实现快速软件卸载；新鲜好看每日更新，让你爽到尖叫停不下来`
        // },{
        //     name:"超速清理大师",
        //     imgIcon:require('./../assets/icon/chaosuqingli.png').default,
        //     backImg:require('./../assets/background/chaosuqingli_page.png').default,
        //     descText:`超速清理大师是一款专业的系统清理应用,帮助您清除手机中重复的照片(包括相似的照片)以及大尺寸照片,扫描出低质量的照片,对照片智能分类以便于清除不需要的照片释放存储空间,清理重复视频和大尺寸视频`
        // }
        // ,{
        //     name:"365清理大师",
        //     imgIcon:require('./../assets/icon/365qingli.png').default,
        //     backImg:require('./../assets/background/365qingli_page.png').default,
        //     descText:`365清理大师 是应用商店最好的清理类应用之一。365清理大师 是一款集整理照片、视频、音频、文件清理于一体，同时能够快速清理的神奇应用。365清理大师 能够帮助您清理照片&视频，并为您的手机加速和降温。您的手机是否运行越来越慢，内存也越来越大？我们的365清理大师 就是您所需要的加速器和相片垃圾清理器！快速轻松地清理!`
        // }
    ]);
    //定义当前所在的下标
    const [currentIndex, setCurrentIndex] = useState(0);
    //判断左右切换组件是否hover
    const [isLeftHover, setIsLeftHover] = useState<boolean>(true);
    const [isRightHover, setIsRightHover] = useState<boolean>(true);
    //判断imgIcon是否hover
    const [isImgIconHover, setIsImgIconHover] = useState<number | null>(null);
    //Carousel的ref对象
    const varousel = React.useRef();


    /**
     * 切换到下一张
     */
    const next = (val: any) => {
        let index = currentIndex + 1;
        if (index >= tabList.length) {
            index = 0
        }
        setCurrentIndex(index)
        varousel.current.next();
    }

    /**
     * 切换到上一张
     */
    const prev = () => {
        let index = currentIndex - 1;
        if (index < 0) {
            index = tabList.length - 1;
        }
        setCurrentIndex(index)
        varousel.current.prev();
    }

    const goIndex = (index: number) => {
        setCurrentIndex(index)
        varousel.current.goTo(index);
    }

    return (
        <div className="productBox" style={{"height": window.innerHeight - 100 + 'px'}}>
            <div className="contentBox">
                <p className="title">我的产品</p>
                <div className="tabBar">
                    {
                        tabList.map((item, index) => {
                            return (
                                <div className="imgIconBox">
                                    <img
                                        onMouseEnter={() => setIsImgIconHover(index)}
                                        onMouseLeave={() => setIsImgIconHover(null)}
                                        onClick={() => goIndex(index)}
                                        className={
                                            currentIndex == index ?
                                                "imgIconHover"
                                                :
                                                isImgIconHover == index ?
                                                    "imgIconHover"
                                                    :
                                                    "imgIcon"
                                        }
                                        src={item.imgIcon}
                                        alt=""
                                    />
                                </div>
                            )
                        })
                    }
                </div>
                <div className="dotsBoxBtn"
                >
                    <div className="leftIconBox"
                         onMouseEnter={() => setIsLeftHover(false)}
                         onMouseLeave={() => setIsLeftHover(true)}
                         onClick={() => prev()}
                    >
                        <img
                            className="IconImg"
                            src={
                                !!isLeftHover ?
                                    require('./../assets/icon/switch_left_uncheck.png').default
                                    :
                                    require('./../assets/icon/switch_left_check.png').default
                            } alt=""/>
                    </div>
                    <div className="leftIconBox"
                         onMouseEnter={() => setIsRightHover(false)}
                         onMouseLeave={() => setIsRightHover(true)}
                         onClick={(val) => next(val)}
                    >
                        <img
                            className="IconImg"
                            src={
                                !!isRightHover ?
                                    require('./../assets/icon/switch_right_uncheck.png').default
                                    :
                                    require('./../assets/icon/switch_right_check.png').default
                            } alt=""/>
                    </div>
                </div>
                <Carousel
                    effect="scrollx"
                    dots={false}
                    draggable={true}
                    ref={varousel}
                >
                    {tabList.map((item, index) => {
                        return (
                            <div key={index} className="itemBox">
                                <div className="itemContent">
                                    <img src={require('./../assets/background/iphone.png').default} className="phoneImg" alt=""/>
                                    <img className="backImg" src={item.backImg} alt=""/>
                                    <div className="itemLeft">
                                        <p className="itemTittle">{item.name}</p>
                                        <div className='containerRow'>
                                            <p className="line"></p>
                                            <a style={{display:item.name=='66输入法'?"block":"none"}} className='downloadButton' href='https://albumsoss.dmpdsp.com/appResUp/liuliu_keyboard_release_v1.0.1.apk'>立即下载</a>
                                            <a style={{display:item.name=='天天走路宝'?"block":"none"}} className='downloadButton' href='https://albumsoss.dmpdsp.com/appResUp/lesiter-v1.0.0-100-lesiter_huawei-2023-12-07-163403.apk'>立即下载</a>
                                        </div>
                                        <span className="descText">{item.descText}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Carousel>
            </div>
        </div>
    )

}

export default Product
