import './home.scss'

import { useState } from 'react'
import { Carousel } from 'antd';


//首页
const Home:React.FunctionComponent  = () => {
    //首页banner列表
    const [bannerList] = useState<Array<{imgSrc:string,title:string,desc:string}>>([
        {imgSrc:require('./../assets/background/banner1.jpg').default,title:"移动互联网产品研发",desc:"创新·研究·突破"},
        {imgSrc:require('./../assets/background/banner2.jpg').default,title:"移动互联网产品研发",desc:"创新·研究·突破"},
        {imgSrc:require('./../assets/background/banner3.jpg').default,title:"移动互联网产品研发",desc:"创新·研究·突破"},
        {imgSrc:require('./../assets/background/banner4.jpg').default,title:"移动互联网产品研发",desc:"创新·研究·突破"}
    ])
    

    return(
        <div className="homePage">
            <Carousel effect="scrollx" dots={true} draggable={true}
                autoplay={true}
            >
                {bannerList.map((item,index)=>{
                    return(
                        <div key={index} className="itemBox">
                            <div className="textBox">
                                <div className="textBoxSm">
                                    <text className="titleText">{item.title}</text>
                                    <text className="descText">{item.desc}</text>
                                </div>
                            </div>
                            <img className="bannerImg" style={{"height":window.innerHeight + 'px'}} src={item.imgSrc}></img>
                        </div>
                    )
                })}
            </Carousel>
        </div>
    )

}

export default Home